<template>
  <div id="up" class="ticket">
    <!--    <base-loader v-if="isLoading" class="open-ticket__loader" />-->
    <base-loader v-if="loading" class="open-ticket__loader" />
    <div v-if="isReady && !loading" class="ticket-info">
      <plain-button
        :to="{ name: 'OpenTicket' }"
        icon="arrow-left"
        style="justify-content: left"
        color="dim"
      >
        {{ $t('back') }}
      </plain-button>
      <div class="ticket-info__title">
        <div>
          <div>{{ `Тикет #${currentTicketInfo.id}` }}</div>
          <page-title class="ticket-info__title-name">
            {{ ticketName }}
          </page-title>
          <label v-if="currentTicketInfo.info_item_name">{{
            currentTicketInfo.info_item_name
          }}</label>
        </div>
        <main-label v-if="getStatus" :color="getStatusColor" class="ticket-info__title-status">
          {{ getStatus }}
        </main-label>
      </div>
      <transition-group mode="slide-fade">
        <base-dialog
          v-for="msg in ticketList"
          :key="msg.id"
          :el="lastReadMessage === msg.id ? msg.id : ''"
          :plid="id"
          :date="new Date(msg.date_post)"
          :elid="msg.id"
          :theme="currentTicketInfo.info_item_name"
          :name="msg.user.realname"
          :position="msg.user.position"
          :time="msg.date_post_locale"
          :text="msg.body"
          :image="srcAva(msg.type, msg.avatar)"
          :type="msg.type"
          :files="msg.file"
          :rate="msg.rates"
          class="ticket-info__dialogs"
          @update-rate="onChange"
        />
      </transition-group>
      <send-message :arhive="toArhive" />
      <!--      <div v-if="ticketList.length > 2" id="down" class="ticket-info__up">
        <plain-button
          v-scroll-to="{
            el: '#up',
            duration: 1000,
            lazy: true,
            easing: 'ease',
            offset: -100,
          }"
          icon="arrow-up"
          color="dim"
        >
          {{ $t('up') }}
        </plain-button>
      </div>-->
    </div>
  </div>
</template>

<i18n>
{
  "ru": {
    "back": "Назад",
    "up": "Наверх",
    "down": "Вниз"
  }
}
</i18n>

<script>
/**
 * @TODO:
 * - убрать стор-геттер из шаблона в computed
 * - в srcAva хост получать из настроек пиложения
 * - удалить неиспользуемые функции
 */
import BaseDialog from '@/components/BaseChat/BaseDialog';
import SendMessage from '@/layouts/Support/component/SendMessage';
import MainLabel from '@/components/Labels/MainLabel';
import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);
export default {
  name: 'Ticket',
  components: {
    BaseDialog,
    SendMessage,
    MainLabel,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      ticket: null,
      error: null,
      // isLoading: true,
      currentTicketInfo: {},
      windowTop: window.top.scrollY,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.moduleSupport.isLoading;
    },
    lastReadMessage() {
      return this.currentTicketInfo.last_message_id_open;
    },
    // mainMessage() {
    //   return this.$store.state.moduleSupport.mainMessage;
    // },
    ticketName() {
      return this.$store.state.moduleSupport.currentTicketTheme;
    },
    ticketList() {
      return this.$store.state.moduleSupport.currentTicket.filter(function (v, i) {
        return (
          // (!v.body.startsWith('Продукт/услуга') && v.type === 'system') ||
          // (!v.body.startsWith('Запрос скопирован') && v.type === 'system') ||
          v.type !== 'system'
        );
      });
    },
    isReady() {
      return this.ticketList && this.ticketList.length;
    },
    // isScrollable() {
    //   return document.documentElement.clientHeight;
    // },
    getStatus() {
      if (this.currentTicketInfo.tstatus === '6') {
        return 'Вопрос решен';
      } else if (this.currentTicketInfo.tstatus === '1') {
        return 'На рассмотрении';
      } else {
        return 'Ожидаем Вашего ответа';
      }
    },
    getStatusColor() {
      if (this.currentTicketInfo.tstatus === '6') {
        return 'success';
      } else if (this.currentTicketInfo.tstatus === '1') {
        return 'primary';
      } else {
        return 'error';
      }
    },
    // isArhive() {
    //   return this.$route.path.includes('archive');
    // },
    toArhive() {
      // return this.currentTicketInfo.alt_status === '5';
      return this.currentTicketInfo.toarchive === 'on';
    },
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.fetchData().then(() => this.pageScroll());
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll, true);
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll, true);
    // this.fetchData().then(() => this.pageScroll());
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleSupport/reset');
    next();
  },
  methods: {
    fetchData() {
      this.loading = true;
      return this.$store
        .dispatch('moduleSupport/fetchCurrentTicket', this.$route.params.id)
        .then(data => {
          this.currentTicketInfo = data.model;
          this.loading = false;
        });
    },
    onScroll(e) {
      this.windowTop = window.document.body.clientHeight;
    },
    pageScroll() {
      const scrollDiv = document.getElementById(this.lastReadMessage).offsetTop;
      window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
    },
    onChange() {
      // console.log('sendRate');
      this.$store.dispatch('moduleSupport/fetchCurrentTicket', this.$route.params.id).then(data => {
        this.currentTicketInfo = data.model;
      });
    },
    srcAva: function (type, image) {
      if (type === 'outcoming') {
        return this.$store.getters['moduleProfile/avatar'];
      } else if (type === 'incoming') {
        return `${this.$store.state.moduleApp.host}${image}`;
      }
      return '';
    },
    routeTo() {
      this.$router.push({ name: 'OpenTicket' });
    },
  },
};
</script>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.ticket {
  display: flex;
  flex-direction: column;
  width: 100%
  max-width: 925px;

  &-info {
    display: flex;
    flex-direction: column;
    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between
      margin-top: 1rem;
      margin-bottom: 2.5rem;

      &-status {
        flex: 0 0 auto;
        order: 3;
        margin: 0 0 0 auto;

        +breakpoint(sm-and-up) {
          order: 2;
          margin: 0 1.25rem 0.25rem;
        }
      }
    }

    &__dialogs {
      margin-bottom: 4rem;
    }
    &__up{
      text-align: center;
      text-align: -webkit-center;

    }
  }
}
</style>
